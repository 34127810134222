<template>
	<div class="col-12 col-xl-6">
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-5">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.CustomerCenter.MemberAdministration.News.title') }}</span>
				</h3>
				<div class="card-toolbar align-items-start">
					<a href="" class="btn btn-icon btn-light btn-sm mx-1" v-on:click.prevent="addNews">
						<i class="fas fa-plus text-primary"></i>
					</a>
				</div>
			</div>
			<div class="card-body pt-3 prinor-table">
				<b-table :data="news" :paginated="true" :per-page="5" :default-sort="'created_at'" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
					<b-table-column field="title" :label="$t('GENERAL.General.title')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.title }}</span>
						</template>
					</b-table-column>
					<b-table-column field="channels" :label="$t('VIEWS.CustomerCenter.MemberAdministration.News.channels')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span>{{ props.row.channels.map(e => e.name).join(', ') }}</span>
						</template>
					</b-table-column>
					<b-table-column field="created_at" :label="$t('VIEWS.CustomerCenter.MemberAdministration.News.publishedAt')" v-slot="props" :sortable="true" cell-class="text-valign">
						<template>
							<span>{{ new Date(props.row.created_at).toLocaleString() }}</span>
						</template>
					</b-table-column>
					<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="action-td d-grid" header-class="action-td">
						<template>
							<a href="#" v-on:click.prevent="editNews(props.row.id)" class="btn btn-icon btn-light btn-sm mx-1" :title="$t('VIEWS.CustomerCenter.MemberAdministration.News.actionEdit')">
								<i class="fas fa-edit text-primary"></i>
							</a>
							<a href="#" v-on:click.prevent="deleteNews(props.row.id)" class="btn btn-icon btn-light btn-sm text-hover-danger mx-1" :title="$t('GENERAL.Buttons.delete')">
								<i class="fas fa-trash text-primary"></i>
							</a>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>

		<b-modal
			ref="edit-modal"
			content-class="modal-border prinor-editor-large"
			size="xl"
			:title="(create_mode) ? $t('VIEWS.CustomerCenter.MemberAdministration.News.Create.title') : $t('VIEWS.CustomerCenter.MemberAdministration.News.actionEdit')"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Forms.save')"
			v-on:ok="applyNews"
			:on-cancel="resetDialog"
			v-on:shown="initEditor"
			scrollable
		>
			<b-tabs content-class="mt-3" justified>
				<b-tab :title="$t('GENERAL.General.content')" active>
					<b-form-group :label="$t('GENERAL.General.title')">
						<b-form-input type="text" v-model="item.title"></b-form-input>
					</b-form-group>

					<b-form-group class="prinor-editor">
						<label>{{ $t('GENERAL.General.text') }}:</label>
						<div id="content-editor" ref="content" v-html="news_content"></div>
					</b-form-group>
				</b-tab>
				<b-tab :title="$t('GENERAL.General.advanced')">
					<b-form-group class="col-12" :label="$t('VIEWS.CustomerCenter.MemberAdministration.News.Create.newsPicture')">
						<b-form-file
							:placeholder="$t('VIEWS.CustomerCenter.MemberAdministration.News.Create.uploadPlaceholder')"
							drop-placeholder="Bild hier ablegen..."
							@change="pictureChange"
						></b-form-file>
						<span class="form-text text-muted">{{ $t('VIEWS.CustomerCenter.MemberAdministration.News.Create.uploadPlaceholder') }}</span>
					</b-form-group>
					<b-form-group class="col-12 col-md-4" :label="$t('VIEWS.CustomerCenter.MemberAdministration.News.channels')" v-if="configuration && configuration.channels">
						<multiselect v-model="item.channels" :options="configuration.channels" :multiple="true" label="name" track-by="id"
						             :placeholder="$t('VIEWS.CustomerCenter.MemberAdministration.News.Create.channelPlaceholder')"
						             :select-label="$t('GENERAL.Multiselect.select')"
						             :selected-label="$t('GENERAL.Multiselect.selected')"
						             :deselect-label="$t('GENERAL.Multiselect.deselect')"></multiselect>
					</b-form-group>
				</b-tab>
			</b-tabs>
		</b-modal>
	</div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {PRINOR_PROCESSES} from "@/core/services/actions.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";
import Swal from "sweetalert2";
import { NIL as NIL_UUID } from 'uuid';

export default {
	name: "MemberNews",
	props: {
		configuration: Object,
		news: Array,
	},
	data: function() {
		return {
			item: {
				id: 'new',
				title: '',
				content: '',
				image: '',
				channels: [],
			},
			news_content: '',
			fileReader: new FileReader(),
			create_mode: false,
		};
	},
	mounted() {
		let that = this;
		this.fileReader.onloadend = function () {
			that.item.image = that.fileReader.result.replace(/^data:.+;base64,/, '');
		};
	},
	methods: {
		deleteNews: function(id) {
			Swal.fire({
				title: this.$t('GENERAL.Buttons.delete'),
				icon: 'question',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					ApiService.delete('member-administration/news/delete/' + id).then(data => {
						PRINOR_PROCESSES.reset();
						if (ApiService.checkSuccess(data)) {
							let index = -1;
							for (let i = 0; i < this.news.length; i++) {
								if (this.news[i].id === id) {
									index = i;
								}
							}

							if (index !== -1) {
								this.news.splice(index, 1);
							}

							this.resetDialog();
						}
						else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}
					});
				}
			});
		},
		editNews: function(id) {
			let arr = this.news.filter(value => { return value.id === id; });
			if (arr != null && arr.length === 1) {
				this.item = arr[0];
				this.news_content = this.item.content;
				this.create_mode = false;
				this.$refs['edit-modal'].show();
			}
		},
		addNews: function() {
			this.resetDialog();
			this.$refs['edit-modal'].show();
		},
		resetDialog: function() {
			this.create_mode = true;
			this.item = {
				id: NIL_UUID,
				title: '',
				content: '',
				image: '',
				channels: [],
			};
			this.news_content = null;
		},
		applyNews: function(bvModalEvent) {
			bvModalEvent.preventDefault()
			ApiService.post('member-administration/news/apply', this.item).then(data => {
				PRINOR_PROCESSES.reset();
				if (ApiService.checkSuccess(data)) {
					PRINOR_TOASTS.save.confirmation(this);
					this.$emit('applyNews', data.data.data);
					this.$refs['edit-modal'].hide();
					this.resetDialog();
				}
				else {
					PRINOR_TOASTS.save.error(this, data.data.error);
				}
			});
		},
		initEditor: function() {
			let that = this;

			let toolbarOptions = [
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				['bold', 'italic', 'underline', 'strike'],
				[{ 'list': 'ordered'}, { 'list': 'bullet' }],
				[{ 'color': [] }, { 'background': [] }],
				// ['image'],
			];

			let options = {
				modules: {
					toolbar: toolbarOptions
				},
				placeholder: this.$t('VIEWS.CustomerCenter.MemberAdministration.News.Create.contentPlaceholder'),
				theme: 'snow'
			};

			this.quill = new window.Quill('#content-editor', options);
			this.quill.on('text-change', function() {
				let delta = that.quill.getContents();
				let qdc = new window.QuillDeltaToHtmlConverter(delta.ops, window.opts_ || {});
				qdc.renderCustomWith(function(customOp){
					if (customOp.insert.type === 'TemplateMarker') {
						let val = customOp.insert.value;
						return val.marker;
					}
				});

				that.item.content = qdc.convert();
			});
		},
		pictureChange: function(event) {
			let file = event.target.files[0];
			this.fileReader.readAsDataURL(file);
			// this.pictureFrontend = URL.createObjectURL(file);
		},
	}
}
</script>

<style scoped>

</style>