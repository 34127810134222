<template>
	<div class="col-12 col-xl-6">
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-5">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.title') }}</span>
					<span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.subtitle') }}</span>
				</h3>
			</div>
			<div class="card-body pt-3">
				<div class="mb-5">
					<b-form-checkbox v-model="showCancelled" size="lg" switch>{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.showCanceled') }}</b-form-checkbox>
				</div>
				<div class="prinor-table">
					<b-table :data="filteredAppointments" :paginated="true" :per-page="5" :default-sort="'created_at'" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
						<b-table-column field="name" :label="$t('VIEWS.CustomerCenter.MemberAdministration.Appointment.customer')" v-slot="props" cell-class="text-valign">
							<template>
								<span>{{props.row.member_details.customer.first_name}} {{props.row.member_details.customer.last_name}}</span>
							</template>
						</b-table-column>
						<b-table-column field="reason" :label="$t('VIEWS.CustomerCenter.MemberAdministration.Appointment.reason')" cell-class="text-valign" searchable :custom-search="reasonSearch">
							<template v-slot:searchable="props">
								<b-select v-model="props.filters[props.column.field]" :options="reasonArray" value-field="id" text-field="name" ></b-select>
							</template>
							<template v-slot="props">
								<span>{{getReason(props.row.reason_id)}}</span>
							</template>
						</b-table-column>
						<b-table-column field="content" :label="$t('GENERAL.General.message')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
							<template>
								<span class="truncate">{{ props.row.content }}</span>
							</template>
						</b-table-column>
						<b-table-column field="status" :label="$t('GENERAL.General.status')" v-slot="props" :sortable="true" cell-class="text-valign">
							<template>
								<template>
									<span v-if="props.row.status === 0">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.Status.received') }}</span>
									<span v-if="props.row.status === 1">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.Status.canceled') }}</span>
									<span v-if="props.row.status === 2">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.Status.accepted') }}</span>
									<span v-if="props.row.status === 3">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.Status.completed') }}</span>
									<span v-if="props.row.status === 4">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.Status.customerCanceled') }}</span>
								</template>
							</template>
						</b-table-column>
						<b-table-column field="suggested_datetime" :label="$t('VIEWS.CustomerCenter.MemberAdministration.Appointment.desiredDate')" v-slot="props" :sortable="true" cell-class="text-valign">
							<template>
								<span>{{ new Date(props.row.suggested_datetime).toLocaleString() }}</span>
							</template>
						</b-table-column>
						<b-table-column field="created_at" :label="$t('VIEWS.CustomerCenter.MemberAdministration.Appointment.sentAt')" v-slot="props" :sortable="true" cell-class="text-valign">
							<template>
								<span>{{ new Date(props.row.created_at).toLocaleString() }}</span>
							</template>
						</b-table-column>
						<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="action-td d-grid" header-class="action-td">
							<template>
								<b-dropdown size="xs" variant="link" toggle-class="btn btn-icon btn-light btn-dropdown btn-sm mr-1 text-decoration-none" no-caret right no-flip :title="$t('VIEWS.Dashboard.Active.share.shareTitle')">
									<template v-slot:button-content>
										<i class="fas fa-bars text-primary"></i>
									</template>
									<b-dropdown-text tag="div">
										<ul class="navi navi-hover py-4">
											<li class="navi-item" v-if="props.row.status !== 2 && props.row.status !== 4 && props.row.status !== 3">
												<a href="" v-on:click.prevent="acceptAppointment(props.row.id)" class="navi-link">
													<span class="symbol symbol-20 mr-3">
														<i class="fas fa-check text-primary"></i>
													</span>
													<span class="navi-text">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.Actions.accept') }}</span>
												</a>
											</li>
											<li class="navi-item" v-if="props.row.status !== 1 && props.row.status !== 3 && props.row.status !== 4">
												<a href="" v-on:click.prevent="declineAppointment(props.row.id)" class="navi-link">
													<span class="symbol symbol-20 mr-3">
														<i class="fas fa-times-circle text-primary"></i>
													</span>
													<span class="navi-text">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Appointment.Actions.cancel') }}</span>
												</a>
											</li>
											<li class="navi-item" v-if="props.row.status !== 0">
												<a href="" v-on:click.prevent="deleteAppointment(props.row.id)" class="navi-link">
														<span class="symbol symbol-20 mr-3">
															<i class="fas fa-trash text-primary"></i>
														</span>
													<span class="navi-text">{{ $t('GENERAL.Buttons.delete') }}</span>
												</a>
											</li>
										</ul>
									</b-dropdown-text>
								</b-dropdown>
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {PRINOR_PROCESSES} from "@/core/services/actions.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";

export default {
	name: "MemberAppointment",
	props: {
		appointments: Array,
		configuration: Object,
	},
	data: function() {
		return {
			showCancelled: false,
		};
	},
	computed: {
		filteredAppointments: function() {
			if (this.showCancelled) {
				return this.appointments;
			}
			else {
				return this.appointments.filter(value => { return value.status !== 1 && value.status !== 4; });
			}
		},
		reasonArray: function() {
			let arr = [];
			arr.push({
				id: '',
				name: ''
			});

			return arr.concat(this.configuration.reasons);
		}
	},
	methods: {
		getReason: function(id) {
			let arr = this.configuration.reasons.filter(value => {return value.id === id; });
			if (arr != null && arr.length > 0) {
				return arr[0].name;
			}

			return null;
		},
		acceptAppointment: function(id) {
			let arr = this.appointments.filter(value => { return value.id === id; });
			if (arr != null && arr.length === 1) {
				ApiService.post('member-administration/appointment/accept', arr[0]).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						PRINOR_TOASTS.save.confirmation(this);
						arr[0].status = 2;
					}
					else {
						PRINOR_TOASTS.save.error(this, data.data.error);
					}
				});
			}
		},
		declineAppointment: function(id) {
			let arr = this.appointments.filter(value => { return value.id === id; });
			if (arr != null && arr.length === 1) {
				ApiService.post('member-administration/appointment/decline', arr[0]).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						PRINOR_TOASTS.save.confirmation(this);
						arr[0].status = 1;
					}
					else {
						PRINOR_TOASTS.save.error(this, data.data.error);
					}
				});
			}
		},
		deleteAppointment: function(id) {
			let arr = this.appointments.filter(value => { return value.id === id; });
			if (arr != null && arr.length === 1) {
				ApiService.post('member-administration/appointment/delete', arr[0]).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						PRINOR_TOASTS.save.confirmation(this);
						this.appointments = this.appointments.filter(value => { return value.id !== id; });
					}
					else {
						PRINOR_TOASTS.save.error(this, data.data.error);
					}
				});
			}
		},
		reasonSearch: function(row, search) {
			if (search != null) {
				return search === row.reason_id;
			}
			else
				return true;
		}
	}
}
</script>

<style>
.dropdown-menu.show {
	display: block !important;
	white-space: nowrap;
}
</style>