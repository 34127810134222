<template>
	<div>
		<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
<!--		<NoData v-if="charts.length === 0 && customers.length === 0 && !pageProcesses.isLoading"></NoData>-->
		<div v-if="!pageProcesses.isLoading">
			<div class="row">
				<MemberNews v-if="hasPermission('action.memberapp.news')" :configuration="configuration" :news="news" @applyNews="applyNews"></MemberNews>
				<MemberAppointment v-if="hasPermission('action.memberapp.appointment')" :configuration="configuration" :appointments="appointments"></MemberAppointment>
			</div>
			<div class="row">
				<MemberInformation v-if="hasPermission('action.memberapp.information') && configuration && configuration.service_enabled" :configuration="configuration"></MemberInformation>
				<MemberSettings v-if="hasPermission('action.memberapp.settings')" :configuration="configuration" :theme="configuration.theme"></MemberSettings>
			</div>
		</div>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
// import ContentLoading from '@/view/component/misc/ContentLoading';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {mapGetters} from "vuex";
import Placeholder from "@/data/survey/Placeholder";
import ContentLoading from "@/view/component/misc/ContentLoading";
// import NoData from "@/view/component/misc/NoData";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import MemberNews from "@/view/component/customer-center/MemberNews";
import MemberAppointment from "@/view/component/customer-center/MemberAppointment";
import MemberInformation from "@/view/component/customer-center/MemberInformation";
import MemberSettings from "@/view/component/customer-center/MemberSettings";
// import NoData from "@/view/component/misc/NoData";

export default {
	name: 'MemberCardAdministration',
	components: {MemberSettings, MemberInformation, MemberAppointment, MemberNews, ContentLoading},
	// components: {NoData, ContentLoading },
	data() {
		return {
			configuration: {},
			appointments: [],
			news: [],
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.CustomerCenter.title'),
			},
			{ title: this.$t('VIEWS.CustomerCenter.MemberAdministration.title') },
		]);
		this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
		PRINOR_PROCESSES.isLoading();
		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses']),

		personalPlaceholder: function() {
			return new Placeholder(this);
		},
	},
	methods: {
		applyNews: function(item) {
			let arr = this.news.filter(value => { return value.id === item.id });
			if (arr != null && arr.length === 1) {
				arr[0] = item;
			}
			else {
				this.news.unshift(item);
			}
		},
		onGet: function() {
			this.isLoading = true;
			this.get().then(data => {
				this.configuration = data.data.configuration;
				this.appointments = data.data.appointments;
				this.news = data.data.news;
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('member-administration/all').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
