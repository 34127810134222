<template>
	<div class="col-12 col-xl-6" v-if="theme != null && configuration != null">
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-5">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Settings.title') }}</span>
					<span class="text-muted mt-3 font-weight-bold font-size-sm"></span>
				</h3>
				<div class="card-toolbar align-items-start">
					<b-button variant="primary" class="btn-light btn-sm text-primary font-weight-bold" v-on:click="showDesignModal"><i class="fas fa-edit text-primary"></i> {{ $t('VIEWS.CustomerCenter.MemberAdministration.Settings.editTheme') }}</b-button>
				</div>
			</div>
			<div class="card-body pt-3 modal-border">
				<b-tabs content-class="mt-3" justified>
					<b-tab :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.reasonAdministration')" v-if="configuration.reasons" active>
						<div  class="row">
							<div class="col-12 text-left d-inline-block mb-5">
								<b-button variant="primary" class="btn-light btn-sm text-primary font-weight-bold mr-2" v-on:click="addReason"><i class="fas fa-plus text-primary"></i> {{ $t('GENERAL.Buttons.add') }}</b-button>
								<b-button variant="primary" class="btn-light btn-sm text-primary font-weight-bold" v-on:click="applyReasons"><i class="fas fa-save text-primary"></i> {{ $t('GENERAL.Forms.save') }}</b-button>
							</div>
							<div class="col-12 prinor-table">
								<b-table :data="configuration.reasons" :paginated="true" :per-page="5" :default-sort="'name'" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
									<b-table-column field="name" :label="$t('GENERAL.Forms.name')" :sortable="true" :searchable="true" v-slot="props" cell-class="text-valign">
										<template>
											<b-input type="text" v-model="props.row.name"></b-input>
										</template>
									</b-table-column>
									<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="action-td d-grid" header-class="action-td">
										<template>
											<a href="#" v-on:click.prevent="deleteReason(props.row.id)" class="btn btn-icon btn-light btn-sm text-hover-danger mx-1" :title="$t('GENERAL.Buttons.delete')">
												<i class="fas fa-trash text-primary"></i>
											</a>
										</template>
									</b-table-column>
								</b-table>
							</div>
						</div>
					</b-tab>
					<b-tab :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.channelAdministration')">
						<div  class="row">
							<div class="col-12 text-left d-inline-block mb-5">
								<b-button variant="primary" class="btn-light btn-sm text-primary font-weight-bold mr-2" v-on:click="addChannel"><i class="fas fa-plus text-primary"></i> {{ $t('GENERAL.Buttons.add') }}</b-button>
								<b-button variant="primary" class="btn-light btn-sm text-primary font-weight-bold" v-on:click="applyChannels"><i class="fas fa-save text-primary"></i> {{ $t('GENERAL.Forms.save') }}</b-button>
							</div>
							<div class="col-12 prinor-table">
								<b-table :data="configuration.channels" :paginated="true" :per-page="5" :default-sort="'name'" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
									<b-table-column field="name" :label="$t('GENERAL.Forms.name')" :sortable="true" :searchable="true" v-slot="props" cell-class="text-valign">
										<template>
											<b-input type="text" v-model="props.row.name"></b-input>
										</template>
									</b-table-column>
									<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="action-td d-grid" header-class="action-td">
										<template>
											<a href="#" v-on:click.prevent="deleteChannel(props.row.id)" class="btn btn-icon btn-light btn-sm text-hover-danger mx-1" :title="$t('GENERAL.Buttons.delete')">
												<i class="fas fa-trash text-primary"></i>
											</a>
										</template>
									</b-table-column>
								</b-table>
							</div>
						</div>
					</b-tab>
				</b-tabs>
			</div>
		</div>

		<b-modal
			ref="design-modal"
			content-class="modal-border"
			size="xl"
			:title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.editTheme')"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Forms.save')"
			v-on:ok="applyDesign"
			v-on:shown="isShown"
			scrollable
		>
			<div class="row">
				<div class="col-6 col-md-4">
					<b-button variant="secondary" v-on:click="reloadPreview">{{ $t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.reloadPreview') }}</b-button>
					<ColorInput ref="color-1" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.accentColor')" :color="theme.accent_color" prop-name="accent_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-2" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.accentTextColor')" :color="theme.accent_text_color" prop-name="accent_text_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-3" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.navigationNormalColor')" :color="theme.navigation_normal_color" prop-name="navigation_normal_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-4" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.navigationActiveColor')" :color="theme.navigation_active_color" prop-name="navigation_active_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-5" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.elementColor')" :color="theme.element_color" prop-name="element_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-6" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.elementTextColor')" :color="theme.element_text_color" prop-name="element_text_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-7" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.loginContainerColor')" :color="theme.login_container_color" prop-name="login_container_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-8" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.loginTextColor')" :color="theme.login_text_color" prop-name="login_text_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-9" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.cardBackgroundColor')" :color="theme.card_background_color" prop-name="card_background_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-10" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.cardBorderColor')" :color="theme.card_border_color" prop-name="card_border_color" @set-color="setThemeColor"></ColorInput>
					<ColorInput ref="color-11" :title="$t('VIEWS.CustomerCenter.MemberAdministration.Settings.Theme.backgroundColor')" :color="theme.background_color" prop-name="background_color" @set-color="setThemeColor"></ColorInput>
				</div>
				<div class="col-6 col-md-8 text-right">
					<div ref="preview-box" class="border" id="member-preview">
						<iframe id="preview-frame" width="100%" height="100%" src="http://localhost:8081/preview"></iframe>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import ColorInput from "@/view/component/designer/ColorInput";
import { v4 as uuidv4 } from 'uuid';
import ApiService from "@/core/services/api.service";
import {PRINOR_PROCESSES} from "@/core/services/actions.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";

export default {
	name: "MemberSettings",
	components: {ColorInput},
	props: {
		theme: Object,
		configuration: Object,
	},
	methods: {
		applyDesign: function() {
			ApiService.post('member-administration/settings/theme', this.theme).then(data => {
				PRINOR_PROCESSES.reset();
				if (ApiService.checkSuccess(data)) {
					PRINOR_TOASTS.save.confirmation(this);
					this.$refs['design-modal'].hide();
				}
				else {
					PRINOR_TOASTS.save.error(this, data.data.error);
				}
			});
		},
		reloadPreview: function() {
			document.getElementById('preview-frame').contentWindow.location.reload();
		},
		showDesignModal: function() {
			this.$refs['design-modal'].show();
		},
		setThemeColor: function(prop, val) {
			this.theme[prop] = val;
		},
		isShown: function() {
			this.$refs['color-1'].initColorInput();
			this.$refs['color-2'].initColorInput();
			this.$refs['color-3'].initColorInput();
			this.$refs['color-4'].initColorInput();
			this.$refs['color-5'].initColorInput();
			this.$refs['color-6'].initColorInput();
			this.$refs['color-7'].initColorInput();
			this.$refs['color-8'].initColorInput();
			this.$refs['color-9'].initColorInput();
			this.$refs['color-10'].initColorInput();
			this.$refs['color-11'].initColorInput();


			let element = document.getElementsByClassName('modal-body')[0];
			let width = this.$refs['preview-box'].clientWidth;
			let height = (width / 9) * 16;

			if (height > element.clientHeight) {
				height = element.clientHeight;
				width = (height / 16) * 9;
			}

			this.$refs['preview-box'].style.height = 'calc(' + height + 'px - 3.5rem)';
			this.$refs['preview-box'].style.width = width + 'px';
			this.$refs['preview-box'].style.position = 'fixed';
		},
		addReason: function() {
			this.configuration.reasons.push({
				id: uuidv4(),
				name: ''
			});
		},
		applyReasons: function() {
			ApiService.post('member-administration/settings/reasons', this.configuration.reasons).then(data => {
				PRINOR_PROCESSES.reset();
				if (ApiService.checkSuccess(data)) {
					PRINOR_TOASTS.save.confirmation(this);
				}
				else {
					PRINOR_TOASTS.save.error(this, data.data.error);
				}
			});
		},
		deleteReason: function(id) {
			this.configuration.reasons = this.configuration.reasons.filter(value => { return value.id !== id; });
		},
		addChannel: function() {
			this.configuration.channels.push({
				id: uuidv4(),
				name: ''
			});
		},
		applyChannels: function() {
			ApiService.post('member-administration/settings/channels', this.configuration.channels).then(data => {
				PRINOR_PROCESSES.reset();
				if (ApiService.checkSuccess(data)) {
					PRINOR_TOASTS.save.confirmation(this);
				}
				else {
					PRINOR_TOASTS.save.error(this, data.data.error);
				}
			});
		},
		deleteChannel: function(id) {
			this.configuration.channels = this.configuration.channels.filter(value => { return value.id !== id; });
		},
	}
}
</script>

<style scoped>

</style>