<template>
	<div class="col-12 col-xl-6">
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-5">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">#Information</span>
					<span class="text-muted mt-3 font-weight-bold font-size-sm">#Informationstab und so</span>
				</h3>
				<div class="card-toolbar align-items-start">
					<a href="" class="btn btn-icon btn-light btn-sm mx-1" v-on:click.prevent="addService">
						<i class="fas fa-plus text-primary"></i>
					</a>
				</div>
			</div>
			<div class="card-body pt-3">
				<div class="row">
					<div class="col-12 col-md-6">
						<div class="form-group row">
							<label class="col-sm-4 col-form-label">Name:</label>
							<div class="col-sm-8">
								<input type="text" class="form-control" v-model="configuration.service_label" />
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 prinor-table">
						<b-table ref="datatable" :data="configuration.services" :paginated="true" :per-page="5" :default-sort="'sorting'" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
							<b-table-column field="name" :label="'#Name'" v-slot="props" cell-class="text-valign">
								<template>
									<span class="truncate">{{ props.row.title }}</span>
								</template>
							</b-table-column>
							<b-table-column field="sorting" :label="'#Reihenfolge'" v-slot="props" cell-class="text-valign" :sortable="true">
								<template>
									<span>{{ props.row.sorting }}</span>
								</template>
							</b-table-column>
							<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="action-td d-grid" header-class="action-td">
								<template>
									<a v-if="props.row.sorting > 1" href="#" v-on:click.prevent="itemUp(props.row.sorting)" class="btn btn-icon btn-light btn-sm mx-1" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
										<i class="fas fa-arrow-up text-primary"></i>
									</a>
									<a v-if="props.row.sorting < configuration.services.length" href="#" v-on:click.prevent="itemDown(props.row.sorting)" class="btn btn-icon btn-light btn-sm mx-1" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
										<i class="fas fa-arrow-down text-primary"></i>
									</a>
									<a href="#" v-on:click.prevent="editService(props.row.sorting)" class="btn btn-icon btn-light btn-sm mx-1" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
										<i class="fas fa-edit text-primary"></i>
									</a>
									<a href="#" v-on:click.prevent="deleteService(props.row.sorting, props.row.title)" class="btn btn-icon btn-light btn-sm text-hover-danger mx-1" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
										<i class="fas fa-trash text-primary"></i>
									</a>
								</template>
							</b-table-column>
						</b-table>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			ref="edit-modal"
			content-class="modal-border"
			size="xl"
			:title="'#Kategorie erstellen'"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Forms.save')"
			v-on:ok="applyService"
			:on-cancel="resetDialog"
			scrollable
		>
			<b-tabs content-class="mt-3" justified>
				<b-tab :title="'#Allgemein'" active>
					<div class="row">
						<b-form-group class="col-12 col-md-6" :label="'#Titel'">
							<b-form-input type="text" v-model="item.title"></b-form-input>
						</b-form-group>
						<b-form-group class="col-12 col-md-6" :label="'#Icon URL'">
							<b-form-input type="text" v-model="item.icon"></b-form-input>
						</b-form-group>
					</div>
					<div class="row">
						<b-form-group class="col-12 col-md-6" :label="'#Terminbuchung möglich'">
							<b-form-radio-group
								v-model="item.is_appointment_visible"
								:options="[
						{ text: $t('GENERAL.General.yes'), value: true },
						{ text: $t('GENERAL.General.no'), value: false },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
							></b-form-radio-group>
						</b-form-group>
						<b-form-group class="col-12 col-md-6" :label="'#Benachrichtigung aktiv'">
							<b-form-radio-group
								v-model="item.notification_enabled"
								:options="[
						{ text: $t('GENERAL.General.yes'), value: true },
						{ text: $t('GENERAL.General.no'), value: false },
					]"
								buttons
								button-variant="outline-primary"
								size="sm"
							></b-form-radio-group>
						</b-form-group>
					</div>
					<div class="row" v-if="item.is_appointment_visible === true && configuration.reasons">
						<b-form-group class="col-12 col-md-6" :label="'#Termin Vorauswahl'">
							<b-select v-model="item.preselect_reason" :options="configuration.reasons" text-field="name" value-field="id"></b-select>
						</b-form-group>
					</div>
					<div class="row">
						<b-form-group class="col-12">
							<label>#Textinhalt:</label>
							<b-textarea rows="4" v-model="item.text"></b-textarea>
						</b-form-group>
					</div>
				</b-tab>
				<b-tab :title="'#Inhalt'">
					<div class="row mb-5">
						<div class="col-12 text-right">
							<b-button variant="success" v-on:click="addContent">
								<i class="fas fa-plus text-light"></i>
								#Inhalt hinzufügen
							</b-button>
						</div>
					</div>
					<div class="row">
						<div class="col-12 prinor-table">
							<b-table ref="contenttable" :data="item.contents" :paginated="true" :per-page="5" :default-sort="'sorting'" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
								<b-table-column field="label" :label="'#Label'" v-slot="props" cell-class="text-valign">
									<template>
										<b-input type="text" v-model="props.row.label"></b-input>
									</template>
								</b-table-column>
								<b-table-column field="content_id" :label="'#Content ID'" v-slot="props" cell-class="text-valign">
									<template>
										<b-input type="text" v-model="props.row.content_id"></b-input>
									</template>
								</b-table-column>
								<b-table-column field="type" :label="'#Typ'" v-slot="props" cell-class="text-valign">
									<template>
										<b-form-select :options="types" value-field="id" text-field="name" v-model="props.row.type"></b-form-select>
									</template>
								</b-table-column>
								<b-table-column field="sorting" :label="'#Reihenfolge'" :sortable="true" v-slot="props" cell-class="text-valign">
									<template>
										<span>{{ props.row.sorting }}</span>
									</template>
								</b-table-column>
								<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="action-td d-grid" header-class="action-td">
									<template>
										<a v-if="props.row.sorting > 1" href="#" v-on:click.prevent="contentUp(props.row.sorting)" class="btn btn-icon btn-light btn-sm mx-1" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
											<i class="fas fa-arrow-up text-primary"></i>
										</a>
										<a v-if="props.row.sorting < item.contents.length" href="#" v-on:click.prevent="contentDown(props.row.sorting)" class="btn btn-icon btn-light btn-sm mx-1" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
											<i class="fas fa-arrow-down text-primary"></i>
										</a>
										<a href="#" v-on:click.prevent="deleteContent(props.row.sorting, props.row.label)" class="btn btn-icon btn-light btn-sm text-hover-danger mx-1" :title="$t('VIEWS.Dashboard.Active.stop.stop')">
											<i class="fas fa-trash text-primary"></i>
										</a>
									</template>
								</b-table-column>
							</b-table>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</b-modal>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import {PRINOR_PROCESSES} from "@/core/services/actions.service";
import {PRINOR_TOASTS} from "@/core/services/toast.service";

export default {
	name: "MemberInformation",
	props: {
		configuration: Object,
	},
	data: function() {
		return {
			item: {},
			types: [
				{
					id: 'date',
					name: 'Datum',
				},
				{
					id: 'text',
					name: 'Text',
				}
			]
		};
	},
	methods: {
		itemUp: function(sorting) {
			if (sorting >= 2) {
				let arr = this.configuration.services.filter(value => { return value.sorting === sorting; });
				let arr2 = this.configuration.services.filter(value => { return value.sorting === (sorting - 1); });

				if (arr != null && arr2 != null) {
					arr[0].sorting = (sorting - 1);
					arr2[0].sorting = sorting;

					this.$refs.datatable.initSort();
				}
			}
		},
		itemDown: function(sorting) {
			if (this.configuration.services.length >= sorting) {
				let arr = this.configuration.services.filter(value => { return value.sorting === sorting; });
				let arr2 = this.configuration.services.filter(value => { return value.sorting === (sorting + 1); });

				if (arr != null && arr2 != null) {
					arr[0].sorting = (sorting + 1);
					arr2[0].sorting = sorting;

					this.$refs.datatable.initSort();
				}
			}
		},
		contentUp: function(sorting) {
			if (sorting >= 2) {
				let arr = this.item.contents.filter(value => { return value.sorting === sorting; });
				let arr2 = this.item.contents.filter(value => { return value.sorting === (sorting - 1); });

				if (arr != null && arr2 != null) {
					arr[0].sorting = (sorting - 1);
					arr2[0].sorting = sorting;

					this.$refs.contenttable.initSort();
				}
			}
		},
		contentDown: function(sorting) {
			if (this.item.contents.length >= sorting) {
				let arr = this.item.contents.filter(value => { return value.sorting === sorting; });
				let arr2 = this.item.contents.filter(value => { return value.sorting === (sorting + 1); });

				if (arr != null && arr2 != null) {
					arr[0].sorting = (sorting + 1);
					arr2[0].sorting = sorting;

					this.$refs.contenttable.initSort();
				}
			}
		},
		addService: function() {
			this.resetDialog();
			this.$refs['edit-modal'].show();
		},
		resetDialog: function() {
			this.item = {
				icon: null,
				is_appointment_visible: true,
				notification_enabled: true,
				preselect_reason: null,
				sorting: -1,
				text: null,
				title: null,
				contents: []
			};
		},
		addContent: function() {
			let count = this.item.contents.length + 1;
			this.item.contents.push({
				content_id: "",
				label: "",
				sorting: count,
				text: null,
				type: "date",
			});
		},
		editService: function(sorting) {
			this.resetDialog();

			let arr = this.configuration.services.filter(value => { return value.sorting === sorting });
			if (arr != null && arr.length === 1) {
				this.item = arr[0];
				this.$refs['edit-modal'].show();
			}
		},
		deleteService: function(sorting, title) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.All.delete'),
				text: this.$t('VIEWS.Survey.All.deleteText', { name: title }),
				icon: 'question',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					ApiService.delete('member-administration/service/delete/' + sorting).then(data => {
						PRINOR_PROCESSES.reset();
						if (ApiService.checkSuccess(data)) {
							this.configuration.services.splice((sorting - 1), 1);

							let counter = 1;
							this.configuration.services.forEach(item => {
								item.sorting = counter;
								counter++;
							});

							this.resetDialog();
						}
						else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}
					});
				}
			});
		},
		deleteContent: function(sorting, title) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.All.delete'),
				text: this.$t('VIEWS.Survey.All.deleteText', { name: title }),
				icon: 'question',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.item.contents.splice((sorting - 1), 1);
				}
			});
		},
		applyService: function(bvModalEvent) {
			bvModalEvent.preventDefault()
			this.item.sorting = this.configuration.services.length + 1;
			this.configuration.services.push(this.item);
			ApiService.post('member-administration/service/apply', this.configuration.services).then(data => {
				PRINOR_PROCESSES.reset();
				if (ApiService.checkSuccess(data)) {
					PRINOR_TOASTS.save.confirmation(this);
					this.$refs['edit-modal'].hide();
					this.resetDialog();
				}
				else {
					PRINOR_TOASTS.save.error(this, data.data.error);
				}
			});
		},
	}
}
</script>

<style scoped>

</style>