<template>
	<div class="form-group">
		<label :for="propName">{{ title }}</label>
		<input :disabled="this.$parent.default === true" type="text" :id="propName" :name="propName" ref="colorInput" v-model="inputColor" class="form-control form-control-solid" :class="{ 'is-invalid': !validColor }" />
		<popper :disabled="this.$parent.default === true" ref="popperComp" trigger="clickToOpen" class="ml-auto" :options="{ placement: 'top', modifiers: { offset: { offset: '0,0px' } } }">
			<div class="popper">
				<chrome-picker v-model="colors" :disableFields="true" />
			</div>
			<div slot="reference" ref="colorBox" class="colorbox" :style="{ height: boxSize + 'px', width: boxSize + 'px', 'background-color': tmpColor, 'margin-top': '-' + (boxSize + margin) + 'px', 'margin-left': margin + 'px', 'border': '1px solid ' + this.color  }"></div>
		</popper>
	</div>
</template>

<script>
export default {
	name: 'ColorInput',
	props: {
		title: String,
		color: String,
		propName: String,
	},
	data() {
		return {
			tmpColor: '',
			boxSize: 0,
			margin: 0,
			inputColor: '',
			validColor: true,
		};
	},
	computed: {
		colors: {
			get: function() {
				return this.tmpColor;
			},
			set: function(v) {
				this.tmpColor = v.hex8;
				this.inputColor = v.hex8;
			},
		},
	},
	mounted() {
		this.tmpColor = this.color;
		this.inputColor = this.color;
		this.initColorInput();
	},
	methods: {
		setParentProp: function(val) {
			this.$emit('set-color', this.propName, val);
		},
		initColorInput: function() {
			this.boxSize = this.$refs.colorInput.clientHeight; //clientHeight;
			this.margin = (this.$refs.colorInput.offsetHeight - this.boxSize) / 2;
		},
	},
	watch: {
		inputColor: function(value) {
			this.tmpColor = value;
			let pattern = new RegExp('^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$', 'i');
			if (pattern.exec(value) !== null) {
				this.validColor = true;
			} else {
				this.validColor = false;
			}
		},
		tmpColor: function(value) {
			this.setParentProp(value);
		},
	},
};
</script>

<style scoped>
div.form-group input {
	padding-left: 40px;
}
div.colorbox {
	background-color: #0a6aa1;
	position: absolute;
	border-bottom-left-radius: 0.3rem;
	border-top-left-radius: 0.3rem;
}
</style>
